import Api from "@/utils/Api";

export default {
  namespaced: true,
  state: {
    dataHeaders: [],
    dataHeadersError: null,
    dataHeadersLoading: false,
    dataHeaderForTable: [],
    dataHeaderForTableError: null,
    dataHeaderForTableLoading: false,
    dataTable: [],
    dataTableError: null,
    dataTableLoading: false,
    dataDialog: new Object({}),
    dataDialogError: null,
    dataDialogLoading: false,
  },
  actions: {
    async getDataOneHeader({commit}, id) {
      commit('SET_DATA_HEADERS_LOADING', true)
      let res = await Api.requestHeadersForTablesByID(id)
      if (res.status === 200) {
        commit('SET_DATA_HEADER_ONE', res.data)
        commit('SET_DATA_HEADER_ONE_LOADING', false)
      } else
        commit('SET_DATA_HEADER_ONE_ERROR', res.error)
    },
    async getDataForHeaders({commit}) {
      commit('SET_DATA_HEADERS_LOADING', true)
      let res = await Api.requestAllHeadersForTablesByOrgType()
      if (res.status === 200) {
        let bufDat = Object.prototype.toString.call(res.data) === '[object Array]' ? res.data : JSON.parse(res.data)
        commit('SET_DATA_HEADERS', bufDat)
        commit('SET_DATA_HEADERS_LOADING', false)
      } else {
        commit('SET_DATA_HEADERS_ERROR', res.error)
        commit('SET_DATA_HEADERS_LOADING', false)
      }
    },
    getDataForTable({commit}, Obj) {
      commit('SET_DATA_TABLE_LOADING', true)
      Api.dataTable(Obj, Obj.params)
        .then(data => {
          commit('SET_DATA_TABLE', data.data)
          commit('SET_DATA_TABLE_LOADING', false)
          commit('SET_DATA_TABLE_ERROR', null)
        })
        .catch(
          err => {
            commit('SET_DATA_TABLE_ERROR', err)
          }
        )
        .finally(() => {
          commit('SET_DATA_TABLE_LOADING', false)
        })
    },
    async deleteDataFromTable({commit, dispatch}, id) {
      commit('SET_DATA_TABLE_LOADING', true)
      let res = await Api.deleteFromDataTable(id)
      if (res.status === 200) {
        dispatch('notifications/addNotification', {
          notificationStatus: 'success',
          notificationMessage: 'Строка удалена',
          timeout: 3000
        }, {root: true})
        commit('SET_DATA_TABLE_LOADING', false)
      } else {
        dispatch('notifications/addNotification', {
          notificationStatus: 'error',
          notificationMessage: 'Ошибка удаления' + res.error,
          timeout: 3000
        }, {root: true})
        commit('SET_DATA_TABLE_LOADING', false)
      }
    },
    async updateDateForTable({commit, dispatch}, data) {
      commit('SET_DATA_DIALOG_LOADING', true)
      let res = await Api.updateTable(data)
      if (res.status === 200) {
        commit('SET_DATA_DIALOG', res.data)
        commit('SET_DATA_DIALOG_LOADING', false)
        commit('SET_DATA_DIALOG_ERROR', null)
        dispatch('notifications/addNotification', {
          notificationStatus: 'success',
          notificationMessage: 'Данные успешно сохранены',
          timeout: 3000
        }, {root: true})
      } else {
        commit('SET_DATA_DIALOG_ERROR', res.error)
        dispatch('notifications/addNotification', {
          notificationStatus: 'error',
          notificationMessage: 'Ошибка добавления',
          timeout: 3000
        }, {root: true})
      }
    },
    async fetchDataForTable({commit, dispatch}, data) {
      commit('SET_DATA_DIALOG_LOADING', true)
      let res = await Api.saveTable(data)
      if (res.status === 200) {
        commit('SET_DATA_DIALOG', res.data)
        commit('SET_DATA_DIALOG_LOADING', false)
        commit('SET_DATA_DIALOG_ERROR', null)
        dispatch('notifications/addNotification', {
          notificationStatus: 'success',
          notificationMessage: 'Данные успешно сохранены',
          timeout: 3000
        }, {root: true})
      } else {
        commit('SET_DATA_DIALOG_ERROR', res.error)
        dispatch('notifications/addNotification', {
          notificationStatus: 'error',
          notificationMessage: 'Ошибка добавления',
          timeout: 3000
        }, {root: true})
      }
    },
  },
  mutations: {
    ['SET_DATA_HEADER_ONE'](state, payload) {
      state.dataHeaderForTable = payload
    },
    ['SET_DATA_HEADER_ONE_LOADING'](state, payload) {
      state.dataHeaderForTableLoading = payload
    },
    ['SET_DATA_HEADER_ONE_ERROR'](state, payload) {
      state.dataHeaderForTableError = payload
    },
    ['SET_DATA_HEADERS'](state, payload) {
      state.dataHeaders = payload
    },
    ['SET_DATA_HEADERS_LOADING'](state, payload) {
      state.dataHeadersLoading = payload
    },
    ['SET_DATA_HEADERS_ERROR'](state, payload) {
      state.dataHeadersError = payload
    },
    ['SET_DATA_TABLE'](state, payload) {
      state.dataTable = payload
    },
    ['SET_DATA_TABLE_LOADING'](state, payload) {
      state.dataTableLoading = payload
    },
    ['SET_DATA_TABLE_ERROR'](state, payload) {
      state.dataTableError = payload
    },
    ['SET_DATA_DIALOG'](state, payload) {
      state.dataDialog = payload
    },
    ['SET_DATA_DIALOG_LOADING'](state, payload) {
      state.dataDialogLoading = payload
    },
    ['SET_DATA_DIALOG_ERROR'](state, payload) {
      state.dataDialogError = payload
    },
  },
  getters: {
    get_data_one_header(state) {
      return state.dataHeaderForTable
    },
    get_data_one_header_loading(state) {
      return state.dataHeaderForTableLoading
    },
    get_data_one_header_error(state) {
      return state.dataHeaderForTableError
    },
    get_data_headers(state) {
      return state.dataHeaders
    },
    get_data_headers_loading(state) {
      return state.dataHeadersLoading
    },
    get_data_headers_error(state) {
      return state.dataHeadersError
    },
    get_data_table(state) {
      return state.dataTable
    },
    get_data_table_loading(state) {
      return state.dataTableLoading
    },
    get_data_table_error(state) {
      return state.dataTableError
    },
    get_data_dialog(state) {
      return state.dataDialog.data
    },
    get_data_dialog_loading(state) {
      return state.dataDialogLoading
    },
    get_data_dialog_error(state) {
      return state.dataDialogError
    },
  }
}