import Api from "@/utils/Api";

export default {
  namespaced: true,
  state: {
    sprDataOrg: {data: [], counter: 0},
    sprDataOrgLoading: false,
    sprDataOrgError: null,
    sprDataRegion: {data: [], counter: 0},
    sprDataRegionLoading: false,
    sprDataRegionError: null,
    sprDataSpec: {data: [], counter: 0},
    sprDataSpecLoading: false,
    sprDataSpecError: null,
    sprDataUsl: {data: [], counter: 0},
    sprDataUslLoading: false,
    sprDataUslError: null,
    sprDataFounder: {data: [], counter: 0},
    sprDataFounderLoading: false,
    sprDataFounderError: null,
    sprDataUgs: {data: [], counter: 0},
    sprDataUgsLoading: false,
    sprDataUgsError: null,
  },
  actions: {
    async dataForSpr({commit}) {
      commit('SET_DATA_SPR_LOADING', true)
      let typesSpr = ['region', 'spec', 'fo', 'founder', 'org']
      commit('SET_DATA_SPR_LOADING', {data: true, type: 'getRegion'})
      commit('SET_DATA_SPR_LOADING', {data: true, type: 'getAllSpec'})
      commit('SET_DATA_SPR_LOADING', {data: true, type: 'getAllFo'})
      commit('SET_DATA_SPR_LOADING', {data: true, type: 'getAllFounder'})
      commit('SET_DATA_SPR_LOADING', {data: true, type: 'getOrg'})

      let [sprResRegion, sprResSpec, sprResFo, sprResFounder, sprResOrg] = await Api.sprDataLoad(typesSpr)
      if (sprResRegion.status === 200) {
        commit('SET_DATA_SPR', {data: sprResRegion.data, type: 'getRegion'})
        commit('SET_DATA_SPR_LOADING', {data: false, type: 'getRegion'})
      } else {
        commit('SET_DATA_SPR_LOADING', {data: false, type: 'getRegion'})
        commit('SET_DATA_SPR_ERROR', {data: sprResRegion.error, type: 'getRegion'})
      }
      if (sprResSpec.status === 200) {
        commit('SET_DATA_SPR', {data: sprResSpec.data, type: 'getAllSpec'})
        commit('SET_DATA_SPR_LOADING', {data: false, type: 'getAllSpec'})
      } else {
        commit('SET_DATA_SPR_LOADING', {data: false, type: 'getAllSpec'})
        commit('SET_DATA_SPR_ERROR', {data: sprResSpec.error, type: 'getAllSpec'})
      }

      if (sprResFo.status === 200) {
        commit('SET_DATA_SPR_LOADING', {data: false, type: 'getAllFo'})
        commit('SET_DATA_SPR', {data: sprResFo.data, type: 'getAllFo'})
      } else {
        commit('SET_DATA_SPR_LOADING', {data: false, type: 'getAllFo'})
        commit('SET_DATA_SPR_ERROR', {data: sprResFo.error, type: 'getAllFo'})
      }

      if (sprResFounder.status === 200) {
        commit('SET_DATA_SPR_LOADING', {data: false, type: 'getAllFounder'})
        commit('SET_DATA_SPR', {data: sprResFounder.data, type: 'getAllFounder'})
      } else {
        commit('SET_DATA_SPR_LOADING', {data: false, type: 'getAllFounder'})
        commit('SET_DATA_SPR_ERROR', {data: sprResFounder.error, type: 'getAllFounder'})
      }

      if (sprResOrg.status === 200) {
        commit('SET_DATA_SPR_LOADING', {data: false, type: 'getOrg'})
        commit('SET_DATA_SPR', {data: sprResOrg.data, type: 'getOrg'})
      } else {
        commit('SET_DATA_SPR_LOADING', {data: false, type: 'getOrg'})
        commit('SET_DATA_SPR_ERROR', {data: sprResOrg.error, type: 'getOrg'})
      }
    },
  },
  mutations: {
    ['SET_DATA_SPR'](state, payload) {
      switch (payload.type) {
        case 'getUsl':
          state.sprDataUsl = {data: payload.data, counter: payload.data.length}
          break
        case 'getRegion':
          state.sprDataRegion = {data: payload.data, counter: payload.data.length}
          break
        case 'getAllFounder':
          state.sprDataFounder = {data: payload.data, counter: payload.data.length}
          break
        case 'getAllSpec':
          state.sprDataSpec = {data: payload.data, counter: payload.data.length}
          break
        case 'getAllFo':
          state.sprDataFo = {data: payload.data, counter: payload.data.length}
          break
        case 'getAllUgs':
          state.sprDataUgs = {data: payload.data, counter: payload.data.length}
          break
        case 'getOrg':
          state.sprDataOrg = {data: payload.data, counter: payload.data.length}
          break
      }
    },
    ['SET_DATA_SPR_LOADING'](state, payload) {
      switch (payload.type) {
        case 'getUsl':
          state.sprDataUslLoading = payload.data
          break
        case 'getRegion':
          state.sprDataRegionLoading = payload.data
          break
        case 'getAllFounder':
          state.sprDataFounderLoading = payload.data
          break
        case 'getAllSpec':
          state.sprDataSpecLoading = payload.data
          break
        case 'getAllFo':
          state.sprDataFoLoading = payload.data
          break
        case 'getAllUgs':
          state.sprDataUgsLoading = payload.data
          break
        case 'getOrg':
          state.sprDataOrgLoading = payload.data
          break
      }
    },
    ['SET_DATA_SPR_ERROR'](state, payload) {
      switch (payload.type) {
        case 'getUsl':
          state.sprDataUslError = payload.data
          break
        case 'getRegion':
          state.sprDataRegionError = payload.data
          break
        case 'getAllFounder':
          state.sprDataFounderError = payload.data
          break
        case 'getAllSpec':
          state.sprDataSpecError = payload.data
          break
        case 'getAllFo':
          state.sprDataFoError = payload.data
          break
        case 'getAllUgs':
          state.sprDataUgsError = payload.data
          break
        case 'getOrg':
          state.sprDataOrgError = payload.data
          break
      }
    },
  },
  getters: {
    get_spr_data_usl(state) {
      return state.sprDataUsl
    },
    get_spr_data_usl_loading(state) {
      return state.sprDataUslLoading
    },
    get_spr_data_usl_error(state) {
      return state.sprDataUslError
    },
    get_spr_data_region(state) {
      return state.sprDataRegion
    },
    get_spr_data_region_loading(state) {
      return state.sprDataRegionLoading
    },
    get_spr_data_region_error(state) {
      return state.sprDataRegionError
    },
    get_spr_data_founder(state) {
      return state.sprDataFounder
    },
    get_spr_data_founder_loading(state) {
      return state.sprDataFounderLoading
    },
    get_spr_data_founder_error(state) {
      return state.sprDataFounderError
    },
    get_spr_data_ugs(state) {
      return state.sprDataUgs
    },
    get_spr_data_ugs_loading(state) {
      return state.sprDataUgsLoading
    },
    get_spr_data_ugs_error(state) {
      return state.sprDataUgsError
    },
    get_spr_data_spec(state) {
      return state.sprDataSpec
    },
    get_spr_data_spec_loading(state) {
      return state.sprDataSpecLoading
    },
    get_spr_data_spec_error(state) {
      return state.sprDataSpecError
    },
    get_spr_data_fo(state) {
      return state.sprDataFo
    },
    get_spr_data_fo_loading(state) {
      return state.sprDataFoLoading
    },
    get_spr_data_fo_error(state) {
      return state.sprDataFoError
    },
    get_spr_data_org(state) {
      return state.sprDataOrg
    },
    get_spr_data_org_loading(state) {
      return state.sprDataOrgLoading
    },
    get_spr_data_org_error(state) {
      return state.sprDataOrgError
    },
  }
}