import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from "vuex-persist";
// import auth from '@/store/auth';
import keycloak from "@/store/keycloak";
import cNotifications from "@/store/cNotifications";
import tables from "@/store/tables";
import spr from "@/store/spr";
import checkFilled from "@/store/checkFilled";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    // auth: state.auth,
    spr: state.spr
  }),
});

export default new Vuex.Store({
  modules: {
    // auth: auth,
    keycloak: keycloak,
    notifications: cNotifications,
    tables: tables,
    spr: spr,
    checkFilled: checkFilled,
  },
  plugins: [vuexLocal.plugin]
})