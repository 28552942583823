import axios from 'axios';
import utils from "@/utils/utils";
import store from "@/store/index.js";

export default {
  async blockEnter(sprOrgId) {
    return axios.get(`/api/userProfile/blockPrint?sprOrgID=${sprOrgId}`, {headers: utils.authHeader()})
  },
  async unblockEnter(sprOrgId) {
    return axios.get(`/api/userProfile/unblockPrint?sprOrgID=${sprOrgId}`, {headers: utils.authHeader()})
  },
  async requestActualOrgStatus(request) {
    return axios.get(`/api/kcpCollectionReturn/getOrgByID?sprOrgID=${store.getters['auth/get_replace_id_admin']}&userProfile=${request?.userProfile}`, {
      headers: utils.authHeader(),
      params: {id: store.getters['auth/get_replace_id_type_org_admin']}
    })
  },
  dataTable(request, params = '?regionID=' + store.getters['auth/getAuthUserData'].regionID) {
    return axios.get('/' + request.link + params, {headers: utils.authHeader()})
  },
  async saveTable(request) {
    return axios.post('/' + request.link, {
      ...request.data,
    }, {headers: utils.authHeader()})
  },
  async updateTable(request) {
    return axios.post('/' + request.link, {
      ...request.data,
    }, {headers: utils.authHeader()})
  },
  async deleteFromDataTable(request) {
    return axios.get('/' + request.link + '?collectionID=' + request.id, {headers: utils.authHeader()})
  },
  async requestHeadersForTablesByID(id) {
    return axios.get('/api/zayavka/getHeaderByID', {headers: utils.authHeader(), params: {id: id}})
  },
  async requestAllHeadersForTables() {
    return axios.post('/api/zayavka/getAllHeaders', null, {headers: utils.authHeader()})
  },
  async requestAllHeadersForTablesByOrgType() {
    return axios.get('/api/zayavka/getHeaderBySprOrgTypeID', {
      headers: utils.authHeader(),
      params: {id: store.getters['auth/get_replace_id_type_org_admin']}
    })
  },
  async sprDataLoad(arr) {
    let request = []
    arr.forEach(el => {
      request.push(axios.get(`/api/spr/${el}/getAll`, {headers: utils.authHeader()}))
    })
    return axios.all(request)
  },
  requestNewsList(data) {
    return axios.post('/api/mainPage/getAllArticles', data);
  },
  requestNewsDetail(data) {
    return axios.get(`/api/mainPage/getOneArticle/?id=${data}`);
  },
  sendMainPageQuestionForm(data) {
    return axios.post('/api/mainPage/saveQuestion', data);
  },
  requestAllDocuments() {
    return axios.post('/api/mainPage/getAllDocuments', {"pageNumber": 0, "recordCount": 6});
  },
  requestAllQuestions() {
    return axios.post('/api/mainPage/getAllQuestions', {"pageNumber": 0, "recordCount": 6});
  },
  requestAllFAQ() {
    return axios.post('/api/mainPage/getAllQuestions', {"pageNumber": 0, "recordCount": 6});
  },
}