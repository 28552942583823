export default {
  namespaced: true,
  state: {
    messages: [],
    timeoutDefault: 3000
  },
  actions: {
    async addNotification({commit}, data) {
      let notificationTime = Date.now().toLocaleString();
      let Obj = {status: data.notificationStatus, message: data.notificationMessage, id: notificationTime};
      commit('ADD_MESSAGE', Obj)
      commit('DELETE_MESSAGE', data.timeout)
    }
  },
  mutations: {
    ['ADD_MESSAGE'](state, payload) {
      state.messages.push(payload)
    },
    ['DELETE_MESSAGE'](state, payload) {
      let timeout = null
      if (payload === undefined)
        timeout = state.timeoutDefault
      else
        timeout = payload
      if (state.messages.length > 0) {
        setTimeout(() => {
          state.messages.splice(0, 1)
        }, timeout)
      }
    }
  },
  getters: {
    get_notifications_messages(state) {
      return state.messages
    }
  }
}