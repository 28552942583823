<template>
  <v-app>
    <CNotificationsFromStore/>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import CNotificationsFromStore from "@/components/CNotificationsFromStore";
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'SPCK',
  components: {
    CNotificationsFromStore,
  },
  props: {
    keycloak: Object
  },
  mounted() {
    this.setAuthData(this.keycloak);
    if (this.getUserRoles.find(el => el === 'ADMIN_ROLE')) {
      this.$route.params.xxx = '12';
    } else if (this.getUserRoles.find(el => el === 'ORGANIZATION_ROLE')) {
      this.$route.params.xxx = 1;
    } else {
      this.$route.params.xxx = 4;
    }
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles'
    })
  },
  methods: {
    ...mapActions('keycloak', {
      setAuthData: 'setAuthData',
      logout: 'logout',
    }),
  }
}
</script>
<style lang="sass">
.v-btn
  &:focus
    border: 0 !important
</style>
