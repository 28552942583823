import Vue from 'vue';
import Router from 'vue-router';
import store from './store/index';

Vue.use(Router);

const routes = new Router({
  mode: 'history',
  routes: [
    // {
    //   path: '/login',
    //   component: () => import('@/layout/LayoutAuth/LayoutAuth'),
    //   meta: {requiresAuth: false},
    //   children: [
    //     {
    //       path: '',
    //       component: LKlogin,
    //       meta: {requiresAuth: false}
    //     },
    //   ],
    // },
    // Пути	ЛК и не ЛК оборачиваются шаблонами
    {
      path: '/',
      component: () => import('@/layout/LayoutLk/LayoutLk'),
      meta: {
        requiresAuth: false,
      },
      children: [
        {
          path: '',
          name: 'LkMain',
          component: () => import('@/views/private/ViewMain'),
          meta: {
            requiresAuth: true,
          },
        },
        // Quota /////////////////////////////////////
        {
          // getAuthUserData.userProfile = 1
          path: '/organizationQuotaTable',
          component: () => import('@/views/OrganizationQuotaTable'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          // getAuthUserData.userProfile = 2
          path: '/adminQuotaTable',
          component: () => import('@/views/AdminQuotaTable'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          // getAuthUserData.userProfile = 2
          path: '/downloadAdmin',
          component: () => import('@/views/DownloadAdmin'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          // getAuthUserData.userProfile = 2
          path: '/organizations',
          component: () => import('@/views/AdminOrganizations'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          // getAuthUserData.userProfile = 2
          path: '/adminQuotaFounders',
          component: () => import('@/views/AdminQuotaFounders'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          // getAuthUserData.userProfile = 2
          path: '/adminQuotaRegions',
          component: () => import('@/views/AdminQuotaRegions'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/quotaTable',
          component: () => import('@/views/QuotaTable'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/quotaPrint',
          component: () => import('@/views/QuotaPrint'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/quotaPrintPrevYears',
          component: () => import('@/views/QuotaPrintPrevYearsFiles'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/contact', // контакты 2
          component: () => import('@/views/private/PropContact'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/dataGather', // Сбор информации 2
          component: () => import('@/views/private/DataGatherView'),
          meta: {
            requiresContacts: true,
            requiresAuth: true,
          },
        },
        {
          path: '/approval', // Согласование с ФОИВ 2
          component: () => import('@/views/private/ApprovalView'),
          meta: {
            requiresContacts: true,
            requiresAuth: true,
          },
        },
        {
          path: '/subjectsData', // Данные субъектов 1
          component: () => import('@/views/private/foivData/SubjectsData'),
          meta: {
            // userRole: 4,
            requiresAuth: true,
          },
        },
        {
          path: '/sprContacts', // контакты 2
          component: () => import('@/views/private/ViewContacts'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/print', // печать
          component: () => import('@/views/private/ViewPrint'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/founders', //
          component: () => import('@/views/private/AdminFounders'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/regions', //
          component: () => import('@/views/private/AdminRegions'),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
  ],
});

routes.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.isAdmin)) {
    if (
      store.getters['keycloak/getUserRoles'] &&
      store.getters['keycloak/getUserRoles'].find((el) => el === 'ADMIN_ROLE')
    ) {
      next();
    } else {
      next({
        path: '/',
      });
    }
  } else {
    next();
  }
});

export default routes;
