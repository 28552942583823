<template>
  <div class="c-notifications" :class="[cssClass.style ? 'c-notifications_' + cssClass.style : '', cssClass.position ? cssClass.position + '__c-notifications' : '']">
    <transition-group tag="div" name="c-notifications_animate" class="c-notifications__list" :class="[cssClass.style ? 'c-notifications__list_' + cssClass.style : '', cssClass.position ? cssClass.position + '__c-notifications__list' : '']">
      <div class="c-notification" :class="[cssClass.style ? 'c-notification_' + cssClass.style : '', cssClass.position ? cssClass.position + '__c-notification' : '', 'c-notification_' + message.status]" v-for="message in get_notifications_messages" :key="message.id">
<!--        <img class="c-notification__status" :class="[cssClass.style ? 'c-notification__status_' + cssClass.style : '', cssClass.position ? cssClass.position + '__c-notification__status' : '']" src="'@/assets/icons/' + messages.status + '.svg'">-->
        <v-icon
            :color="message.status"
            :class="['c-notification__text_' + message.status]">
          {{choseIcon(message.status)}}
        </v-icon>
        <p class="c-notification__text" :class="[cssClass.style ? 'c-notification__text_' + cssClass.style : '', cssClass.position ? cssClass.position + '__c-notification__text' : '', 'c-notification__text_' + message.status]">{{message.message}}</p>
      </div>
    </transition-group>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "notifications",
  props: {
    // messages: {
    //   type: Array,
    //   default: () => {
    //     return []
    //   }
    // },
    // timeout: {
    //   type: Number,
    //   default: 3000
    // },
    cssClass: {
      type: Object,
      default: function() {
        return {position: '', style: ''};
      },
    },
  },
  methods: {
    // hideNotification() {
    //   let notification = this;
    //   if(this.messages.length) {
    //     setTimeout(function () {
    //       notification.messages.splice(0, 1)
    //     }, notification.timeout)
    //   }
    // }
    choseIcon(status) {
        switch (status){
          case 'error':
            return 'mdi-close-octagon-outline'
          case 'warning':
            return 'mdi-alert-outline'
          case 'success':
            return 'mdi-checkbox-marked-circle-outline '
          default:
            return 'mdi-message-text'
        }
    }
  },
  computed:{
    ...mapGetters('notifications',{get_notifications_messages:'get_notifications_messages'})
  },
  // watch: {
  //   messages() {
  //     this.hideNotification()
  //   }
  // },
  mounted() {
    // this.hideNotification()
  },

}
</script>
