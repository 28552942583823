// import request from "@/services/request";

export default {
  namespaced: true,
  state: {
    keycloak: null,
  },
  mutations: {
    ['SET_AUTH_DATA'](state, payload) {
      state.keycloak = payload;
    },
  },
  actions: {
    // async setAuthData({commit, dispatch}, payload) {
    async setAuthData({ commit }, payload) {
      commit('SET_AUTH_DATA', payload);
      // dispatch('getAuthBlock');
      // dispatch('getBlockAllowInfo');
    },
    async logout({ commit, state }) {
      if (state.keycloak) {
        state.keycloak.logout();
        commit('SET_AUTH_DATA', null);
      }
    },
  },
  getters: {
    getAuthData(state) {
      return state.keycloak;
    },
    getUser(state) {
      return state.keycloak.tokenParsed;
    },
    getUserRoles(state) {
      return state.keycloak?.tokenParsed?.realm_access?.roles;
    },
    isAdmin(state) {
      return state.keycloak?.tokenParsed?.realm_access?.roles.find((el) => el === 'ADMIN_ROLE');
    },
    isFounder(state) {
      return state.keycloak?.tokenParsed?.realm_access?.roles.find((el) => el === 'FOUNDER_ROLE');
    },
    isRegion(state) {
      return state.keycloak?.tokenParsed?.realm_access?.roles.find((el) => el === 'REGION_ROLE');
    },
    getUserAccessToken(state) {
      return state.keycloak.token;
    },
  },
};
